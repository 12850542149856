@import url(https://fonts.googleapis.com/css?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
.editor-content {
  margin: 20px;
  max-height: 50vh;
}

.editor-content-error {
  border: 1px solid red;
  margin: 20px;
  max-height: 50vh;
}

.rdw-image-center {
  justify-content: initial;
}

figure {
  margin: 0;
}
/* .App {
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.editer-content {
  margin: 20px,
} */
body {
  margin: 0;
  font-family: "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background: #aaa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* html, body, #root {
  display: block;
  width: 100%;
  height: 100%;
} */

#root {
  /* height: 3000px; */
}

canvas {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.link {
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-decoration: none;
  color: white;
  font-family: Fira Code, monospace;
  font-size: 0.75rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
}

#root {
  /* position: fixed; */
  background: #eaeaea;
  z-index: -1;
}
