body {
  margin: 0;
  font-family: "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background: #aaa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* html, body, #root {
  display: block;
  width: 100%;
  height: 100%;
} */

#root {
  /* height: 3000px; */
}

canvas {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.link {
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-decoration: none;
  color: white;
  font-family: Fira Code, monospace;
  font-size: 0.75rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
}

#root {
  /* position: fixed; */
  background: #eaeaea;
  z-index: -1;
}