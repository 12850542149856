.editor-content {
  margin: 20px;
  max-height: 50vh;
}

.editor-content-error {
  border: 1px solid red;
  margin: 20px;
  max-height: 50vh;
}

.rdw-image-center {
  justify-content: initial;
}

figure {
  margin: 0;
}